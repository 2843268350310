import React, { useState, useEffect, useRef } from "react";
import io from 'socket.io-client';
import SetPos from "./actions/SetPos";
import Draw from "./actions/Draw";
import DrawIO from "./actions/DrawIO";
import { ControlPanel } from "./ui/ControlPanel";

export const App = () => {
  const canvasRef = useRef(null);
  const controlPanelRef = useRef(null);
  //const socket = io.connect('http://localhost:8080');
  const socket = io.connect('https://remote-board-server.onrender.com');
  
  const [mouseData, setMouseData] = useState({ x: 0, y: 0 });
  const [canvasCTX, setCanvasCTX] = useState(null);
  const [color, setColor] = useState("#000000"); // Set default color to black
  const [size, setSize] = useState(10); // Set default size to 10
  const socketRef = useRef();
  let drawing = false;

  const current = {
  };

   // Set the canvas ctx as the state
   useEffect(() => {
    const canvas = canvasRef.current; // Select current canvas element
    const context = canvas.getContext("2d"); // The canvas contxt
    canvas.width = window.innerWidth; // Set canvas width to the screen width
    canvas.height = window.innerHeight; // Set canvas height to the screen height
    setCanvasCTX(context); // Set the State;
  }, [canvasRef]); 

  useEffect(() => {
    let drawing = false;
    const context = canvasRef.current.getContext("2d");
    // ------------------------------- create the drawing ----------------------------

    const drawLine = (x0, y0, x1, y1, color, size, emit) => {
      console.log(canvasRef);
      if (context === null) return;

      context.beginPath();
      context.moveTo(x0, y0);
      context.lineTo(x1, y1);
      context.strokeStyle = color;
      context.lineWidth = size;
      context.lineCap = "round";
      context.stroke();
      context.closePath();

      if (!emit) { return; }
      const w = canvas.width;
      const h = canvas.height;

      socket.emit('drawing', {
        x0: x0 / w,
        y0: y0 / h,
        x1: x1 / w,
        y1: y1 / h,
        color,
        size
      });
    };

    // ---------------- mouse movement --------------------------------------

    const onMouseDown = (e) => {
      drawing = true;
      current.x = e.clientX || e.touches[0].clientX;
      current.y = e.clientY || e.touches[0].clientY;
    };

    const onMouseMove = (e) => {
      if (!drawing) { return; }
      drawLine(current.x, current.y, e.clientX || e.touches[0].clientX, e.clientY || e.touches[0].clientY, color, size, true);
      current.x = e.clientX || e.touches[0].clientX;
      current.y = e.clientY || e.touches[0].clientY;
    };

    const onMouseUp = (e) => {
      if (!drawing) { return; }
      drawing = false;
      drawLine(current.x, current.y, e.clientX || e.touches[0].clientX, e.clientY || e.touches[0].clientY, color, size, true);
    };

    // ----------- limit the number of events per second -----------------------

    const throttle = (callback, delay) => {
      let previousCall = new Date().getTime();
      return function() {
        const time = new Date().getTime();

        if ((time - previousCall) >= delay) {
          previousCall = time;
          callback.apply(null, arguments);
        }
      };
    };

    // -----------------add event listeners to our canvas ----------------------
    const canvas = canvasRef.current;

    canvas.addEventListener('mousedown', onMouseDown, false);
    canvas.addEventListener('mouseup', onMouseUp, false);
    canvas.addEventListener('mouseout', onMouseUp, false);
    canvas.addEventListener('mousemove', throttle(onMouseMove, 10), false);

    // Touch support for mobile devices
    canvas.addEventListener('touchstart', onMouseDown, false);
    canvas.addEventListener('touchend', onMouseUp, false);
    canvas.addEventListener('touchcancel', onMouseUp, false);
    canvas.addEventListener('touchmove', throttle(onMouseMove, 10), false);

    // -------------- make the canvas fill its parent component -----------------

    const onResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    // window.addEventListener('resize', onResize, false);
    // onResize();

    // ----------------------- socket.io connection ----------------------------
    const onDrawingEvent = (data) => {
      const w = canvas.width;
      const h = canvas.height;
      drawLine(data.x0 * w, data.y0 * h, data.x1 * w, data.y1 * h, data.color, data.size);
    }

    socket.on('drawing', onDrawingEvent);
    
  }, [color, size, socketRef]); // Update whenever the canvas element is changed

  return (
    <div>
      
      <canvas
          ref={canvasRef} 
          style={{ border: "1px solid #000000" }}
        ></canvas>
      
      <ControlPanel 
        socket={socket}
        size={size} 
        color={color} 
        setSize={setSize} 
        canvasRef={canvasRef}
        onChange={setColor} 
      />
      Selected color {JSON.stringify(color)}
    </div>
  );
};
