import React, { useState, useEffect, useRef } from "react";
import { ColorPicker } from "./ColorPicker";
import { Socket } from "socket.io-client";

export const ControlPanel = (props) => {
    let clearRect = null;

    useEffect(() => {
        const context = props.canvasRef.current.getContext("2d");    
        clearRect = (x, y, emit) => {
            context.clearRect(x, y, props.canvasRef.current.width, props.canvasRef.current.height);

            if (!emit) return;

            props.socket.emit("clear_canvas", {
                x: 0,
                y: 0,
            });
        };
    
        props.socket.on('clear_canvas', (data) => {
            clearRect(data.x, data.y, false);
        });
    }, [props.socket, props.canvasRef]);
    

    return(
        <div
            className="controlpanel"
            style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
            }}
            >
            <input
                type="range"
                value={ props.size }
                max={40}
                onChange={(e) => {
                    props.setSize(e.target.value);
                }}
            />
            <button
                onClick={() => {
                    const canvas = props.canvas;
                    clearRect(0, 0, true);
                    //props.context.clearRect(0, 0, canvas.width, canvas.height);
                    
                }}
            >
                Clear
            </button>
            <ColorPicker color={ props.color } onChange={ props.onChange } onSelect={ props.onSelect } />
        </div>
    );
};